import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"

const FooterStyles = styled.footer`
  margin-bottom: 140px;
  padding-top: 10px;

  @media (max-width: 1700px) {
    margin-bottom: 90px;
  }

  @media (max-width: 1200px) {
    margin-bottom: 70px;
  }

  h2 {
    font-family: "Open sans";
    font-size: 26px;
    font-weight: 400;
    margin-bottom: 50px;
  }
  .resources {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 40px;
  }
  .resources__subtitle {
    font-family: calluna_bold;
    font-size: 22px;
    line-height: 1.4;
    margin-bottom: 25px;
  }
  .resources__item {
    padding-bottom: 14px;
    line-height: 1.4;
    a {
    font-family: calluna_regular;
    text-decoration: none;
    font-size: 20px;
    color: var(--clr-black); 
    }
    a:hover {
    text-decoration: underline;
    text-decoration-color: inherit;  
    }
  }

  @media (max-width: 1300px) {
    padding-top: 0px;
    h2 {
      font-size: 24px;
      font-weight: 400;
      margin-bottom: 40px;
    }
    .resources__subtitle {
      font-size: 21px;
      margin-bottom: 20px;
    }
    .resources {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 50px;
    }
    .resources__item {
      padding-bottom: 14px;
      line-height: 1.4;
      a {
      font-size: 19px;
      }
    }
  }

  @media (max-width: 800px) {

    h2 {
      font-size: 22px;
      margin-bottom: 36px;
    }
    .resources {
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 30px;
    }
    .resources__subtitle {
      font-size: 22px;
      margin-bottom: 20px;
    }
    .resources__item {
      padding-bottom: 10px;
      line-height: 1.4;
      a {
      font-size: 20px;
      }
    }
  }

  @media (max-width: 600px) {
  
    h2 {
      font-size: 22px;
      margin-bottom: 32px;
    }
    .resources {
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 30px;
    }
    .resources__subtitle {
      font-size: 20px;
      margin-bottom: 14px;
    }
    .resources__item {
      padding-bottom: 10px;
      line-height: 1.4;
      a {
      font-size: 18px;
      }
    }
  }
`

function Footer() {
  const data = useStaticQuery(graphql`
    {
      resources: allSanityResources {
        nodes {
          order
          title
          resourcesList {
            url
            websiteName
          }
        }
      }
    }
  `)

  const resourcesList = data.resources.nodes

  const resourcesListInOrder = resourcesList.sort((a, b) => {
    return a.order - b.order
  })

  return (
    <>
      <FooterStyles>
        <h2>Other Sites and Resources</h2>
        <div className="resources">
          {resourcesListInOrder.map((resource, i) => {
            return (
              <div className="resources__wrap" key={`{${i}-${resource.title}}`}>
                <h3 className="resources__subtitle">{resource.title}</h3>
                <ul className="resources__list">
                  {resource.resourcesList.map((item, i) => {
                    return (
                      <li key={i} className="resources__item">
                        <a
                          href={item.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {item.websiteName}
                        </a>
                      </li>
                    )
                  })}
                </ul>
              </div>
            )
          })}
        </div>
      </FooterStyles>
    </>
  )
}

export default Footer
