import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import Video from "./Video"

const AccordionStyles = styled.div`
  border-bottom: var(--border);
  padding-right: 0px;

  button {
    cursor: pointer;
    padding: 36px 0px;
    width: 100%;
    text-align: left;
    outline: none;
    border: 0;
    font-family: "Open sans";
    font-weight: 600;
    font-size: 25px;
    line-height: 34px;
    background: none;
    color: inherit;

    display: flex;
    /* margin-right: 10px; */
  }

  @media (max-width: 1400px) {
    button {
      padding: 30px 0px;
      font-size: 21px;
    }
  }
  @media (max-width: 1000px) {
    button {
      padding: 28px 0px;
    }
  }
  @media (max-width: 700px) {
    button {
      line-height: 30px;
      padding: 20px 0px;
      font-size: 20px;
    }
  }
  .accordion__icon {
    font-size: 30px;
    color: var(--clr-primary);
    font-family: "Open sans";
    font-weight: 500;
    font-size: 30px;
    line-height: 34px;
    margin-right: 10px;
  }

  @media (max-width: 600px) {
    .accordion__icon {
      font-size: 26px;
    }
  }

  .emphasis {
    color: var(--clr-red);
    margin-left: 8px;
  }
  .accordion__content {
    font-family: calluna_regular;
    font-size: 20px;
    line-height: 30px;
    margin-left: 30px;
    overflow: hidden;
    transition: max-height 0.5s ease-out;
  }

  @media (max-width: 1600px) {
    .accordion__content {
      margin-left: 28px;
    }
  }

  @media (max-width: 1000px) {
    .accordion__content {
      margin-left: 27px;
    }
  }

  @media (max-width: 600px) {
    .accordion__content {
      font-size: 16px;
      line-height: 23px;
      margin-left: 25px;
    }
  }

  @media (max-width: 855px) {
    .grid--wrap {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`

const VideoGridStyles = styled.div`
  margin: 40px 0 45px;

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(310px, 1fr));
  grid-gap: 23px;

  @media (max-width: 1780px) {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }

  @media (max-width: 855px) {
    width: 100%;
    text-align: center;
  }

  @media (max-width: 370px) {
    grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
  }
  @media (max-width: 300px) {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
`

const ArticlesStyles = styled.div`
  h3 {
    font-family: calluna_bold;
    margin-bottom: 20px;
  }
  .articles__list {
    margin-bottom: 60px;

    li {
      margin-bottom: 10px;
    }
  }
  a {
    color: inherit;
    /* text-decoration: none;
    border-bottom: var(--border);
    display: inline-block;
    line-height: 0.75;
    text-shadow: 2px 2px white, 2px -2px white, -2px 2px white, -2px -2px white; */
    transition: all 0.2s ease;
  }
  a:hover {
    text-decoration: none;
  }
`

function Accordion({ title, emphasis, summary, videos, articles }) {
  const [active, setActive] = useState(false)
  const contentRef = useRef(null)

  function toggleActive() {
    setActive(prevValue => {
      return !prevValue
    })
  }

  useEffect(() => {
    contentRef.current.style.maxHeight = active
      ? `${contentRef.current.scrollHeight}px`
      : "0px"
  }, [contentRef, active])

  const icon = !active ? "\u002B" : "\u2212"

  return (
    <AccordionStyles>
      <button type="button" onClick={toggleActive}>
        <span className="accordion__icon">{icon}</span>
        <span className="accordion__title">
          {title}
          {emphasis ? <span className="emphasis">&#42;</span> : ""}
        </span>
      </button>
      <div ref={contentRef} className="accordion__content">
        <div className="panel">
          <p>{summary}</p>
          <div className="grid--wrap">
            <VideoGridStyles>
              {videos.map((video, i) => {
                return <Video key={`${i}${video.title}`} video={video} />
              })}
            </VideoGridStyles>
          </div>
          <ArticlesStyles>
            <h3>Links and Articles</h3>
            <ul className="articles__list">
              {articles.map((article, i) => {
                return (
                  <li key={`${i}${article.title}`}>
                    <a
                      href={article.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {article.title}
                    </a>
                  </li>
                )
              })}
            </ul>
          </ArticlesStyles>
        </div>
      </div>
    </AccordionStyles>
  )
}

export default Accordion
