import React from "react"
import styled from "styled-components"

const IntroStyles = styled.div`
  .intro__title {
    padding-bottom: 4%;
    font-family: "Open sans";
    font-weight: 700;
    font-size: 44px;
    letter-spacing: -2px;
    transition: 0.1s;
  }

  @media (max-width: 1200px) {
    .intro__title {
      padding-bottom: 30px;
      font-size: 40px;
    }
  }
  @media (max-width: 1000px) {
    .intro__title {
      font-size: 38px;
    }
  }
  @media (max-width: 700px) {
    .intro__title {
      padding-bottom: 25px;
      font-size: 32px;
    }
  }

  @media (max-width: 600px) {
    .intro__title {
      font-size: 29px;
    }
  }

  h1 span {
    letter-spacing: -5px;
  }

  .intro__p1 {
    padding-bottom: 25px;
    font-family: calluna_bold;
    font-size: 30px;
    line-height: 36px;
    transition: 0.1s;
  }

  @media (max-width: 1400px) {
    .intro__p1 {
      padding-bottom: 20px;
      font-size: 26px;
      line-height: 32px;
    }
  }

  @media (max-width: 1200px) {
    .intro__p1 {
      padding-bottom: 18px;
      font-size: 24px;
      line-height: 30px;
    }
  }

  @media (max-width: 800px) {
    .intro__p1 {
      padding-bottom: 20px;
      font-size: 22px;
      line-height: 32px;
    }
  }

  .intro__p2 {
    padding-bottom: 5%;
    font-family: calluna_regular;
    font-size: 30px;
    line-height: 36px;
  }

  @media (max-width: 1400px) {
    .intro__p2 {
      padding-bottom: 50px;
      font-size: 26px;
      line-height: 32px;
    }
  }

  @media (max-width: 1200px) {
    .intro__p2 {
      padding-bottom: 40px;
      font-size: 24px;
      line-height: 30px;
    }
  }

  @media (max-width: 800px) {
    .intro__p2 {
      padding-bottom: 40px;
      font-size: 22px;
      line-height: 30px;
    }
  }

  @media (max-width: 600px) {
    .intro__p1,
    .intro__p2 {
      font-size: 21px;
    }
  }

  i {
    font-family: calluna_regular-italic;
  }
`

function Intro() {
  return (
    <IntroStyles>
      <div className="intro">
        <h1 className="intro__title">
          The Covid<span>-</span>19 Global Swindle
        </h1>
        <p className="intro__p1">
          This site hosts a collection of information exposing the
          <span className="nowrap"> so-called</span>{" "}
          <span className="nowrap"> COVID-19 </span>{" "}
          "pandemic" for what it really is: an artificial crisis engineered by
          way of media propaganda and medical fraud in order to justify a
          <span className="nowrap"> multi-pronged</span> agenda of economic
          assault, destructive social transformation and tyranical global
          control.
        </p>
        <p className="intro__p2">
          Coming to terms with the true nature of this historical world changing
          event requires a willingness to look at evidence of an inconvienient
          and challenging nature, as well as the{" "}
          <span className="nowrap">open-mindedness</span> needed to question{" "}
          <span className="nowrap">wide-held</span> beliefs and "authoritative"
          sources of information.
          <i> Click each subtopic below to explore its related content.</i>
        </p>
      </div>
    </IntroStyles>
  )
}

export default Intro
