import React from "react"
import styled from "styled-components"
import "normalize.css"

const MainStyles = styled.main`
  .main {
    width: 100%;
    text-align: center;
    padding: 0 80px;
  }

  @media (max-width: 1200px) {
    .main {
      padding: 0 50px;
    }
  }

  @media (max-width: 600px) {
    .main {
      padding: 0 30px;
    }
  }

  @media (max-width: 350px) {
    .main {
      padding: 0 15px;
    }
  }

  .main__container {
    text-align: left;
    margin: 0 auto;
    width: 100%;
    max-width: 1640px;
    padding-top: 4%;
    padding-bottom: 0px;
    transition: 0.1s;
    text-align: left;
  }

  @media (max-width: 1200px) {
    .main__container {
      padding-top: 34px;
    }
  }
  @media (max-width: 700px) {
    .main__container {
      padding-top: 28px;
    }
  }
`

function Layout({ children }) {
  return (
    <>
      <MainStyles>
        <div className="main">
          <div className="main__container">{children}</div>
        </div>
      </MainStyles>
    </>
  )
}

export default Layout
