import React from "react"
import styled from "styled-components"
import Accordion from "./Accordion"

const SectionStyles = styled.section`
  .section {
    padding-bottom: 5%;
  }

  @media (max-width: 1200px) {
    .section {
      padding-bottom: 50px;
    }
  }
  @media (max-width: 1000px) {
    .section {
      padding-bottom: 60px;
    }
  }
  @media (max-width: 700px) {
    .section {
      padding-bottom: 50px;
    }
  }

  h2 {
    padding-bottom: 10px;
    line-height: 1.2;
    font-family: calluna_regular;
    font-size: 31px;
    color: var(--clr-primary);
    transition: 0.1s;
  }
  @media (max-width: 1400px) {
    h2 {
      padding-bottom: 0px;
      font-size: 28px;
    }
  }
  @media (max-width: 1000px) {
    h2 {
      font-size: 26px;
    }
  }
  @media (max-width: 700px) {
    h2 {
      font-size: 24px;
    }
  }
`

function Section({ section }) {
  const subsections = section.subsections

  return (
    <SectionStyles>
      <div className="section">
        <h2>{section.title}</h2>
        <div className="subsection">
          {subsections.map((subsection, i) => {
            return (
              <Accordion
                key={`${i}${subsection.title}`}
                title={subsection.title}
                videos={subsection.videos}
                articles={subsection.articles}
                emphasis={subsection.emphasis}
                summary={subsection.summary}
              />
            )
          })}
        </div>
      </div>
    </SectionStyles>
  )
}

function Sections({ sections }) {
  return (
    <>
      {sections.map(section => {
        return (
          <Section
            key={section.id}
            title={section.title}
            section={section}
            id={section.id}
          />
        )
      })}
    </>
  )
}

export default Sections
