import { graphql } from "gatsby"
import React, { useContext } from "react"
import Footer from "../components/Footer"
import Intro from "../components/Intro"
import Layout from "../components/Layout"
import Sections from "../components/Sections"
import SEO from "../components/SEO"
import PageTransition from "gatsby-v2-plugin-page-transitions"
import { Context } from "../context/Context"
import Modal from "react-modal"
import ModalVideo from "../components/ModalVideo"

const modalStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.58)",
    position: `fixed`,
    top: 0,
    left: 0,
    bottom: 0,
    backdropFilter: `blur(4px)`,
    zIndex: "100",
    display: `flex`,
    justifyContent: `center`,
    alignContent: `center`,
    border: 0,
  },
  content: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "65vw",
    height: "calc(65vw/1.77)",
    padding: 0,
    border: 0,
    background: "none",
    overflow: `hidden`,
    zIndex: "1000",
  },
}

function HomePage({ data }) {
  const { modalOpen, setModalOpen, videoSrc } = useContext(Context)

  const closeModal = () => {
    setModalOpen(false)
  }

  const sections = data.sections.nodes

  const sectionsInOrder = sections.sort((a, b) => {
    return a.order - b.order
  })

  return (
    <>
      <SEO title="Home" />
      <Modal
        isOpen={modalOpen}
        onRequestClose={closeModal}
        style={modalStyles}
        contentLabel="Modal"
      >
        <ModalVideo videoSrc={videoSrc} />
      </Modal>

      <PageTransition>
        <Layout>
          <Intro />
          <Sections sections={sectionsInOrder} />
          <Footer />
        </Layout>
      </PageTransition>
    </>
  )
}

export default HomePage

export const query = graphql`
  query SectionQuery {
    sections: allSanitySections {
      nodes {
        order
        title
        id
        subsections {
          emphasis
          summary
          title
          articles {
            title
            url
          }
          videos {
            title
            url
            thumbnail {
              asset {
                fluid {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
        }
      }
    }
  }
`

// Warning: Please use `require("history").createBrowserHistory` instead of `require("history/createBrowserHistory")`.
