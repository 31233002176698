import React, { useContext } from "react"
import { AiFillPlayCircle } from "react-icons/ai"
import Img from "gatsby-image"
import styled from "styled-components"
import { Context } from "../context/Context"
// import { Link } from "gatsby"

const VideoStyles = styled.div`
  .video__thumbnail-wrap {
    position: relative;
    cursor: pointer;
    overflow: hidden;
  }
  .img-container {
    overflow: hidden;
  }
  .video__thumbnail {
    border-radius: 7px 7px 0 0;
    object-fit: cover;
    min-height: auto;
    width: 100%;
  }

  @media (max-width: 485px) {
    min-width: 100%;
    height: auto;
  }

  .video__icon {
    /* font-size: 50px; */
    height: 29%;
    width: 29%;
    color: var(--clr-white);
    opacity: 0.9;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
  }
  .video__thumbnail-wrap:hover .video__icon {
    opacity: 0.6;
    transition: all 0.2s ease;
  }
  .video__thumbnail-wrap:focus {
    outline: none;
  }
  .video__title {
    border: var(--border-gray);
    border-top: 0;
    border-radius: 0 0 7px 7px;
    font-family: "Open sans";
    padding: 9px 18px;
    font-size: 16px;
    line-height: 1.5;
    height: 67px;
  }
  @media (max-width: 600px) {
    .video__title {
      font-size: 14px;
      height: 60px;
    }
  }
`

function Video({ video }) {
  const { setVideoSrc, setModalOpen } = useContext(Context)

  function openModal(e) {
    setModalOpen(true)
    const videoURL = video.url
    setVideoSrc(videoURL)
  }

  return (
    <>
      <VideoStyles>
        <div
          className="video__thumbnail-wrap"
          role="button"
          tabIndex={0}
          onClick={openModal}
        >
          <div className="img-container">
            <AiFillPlayCircle className="video__icon" />
            <Img
              className="video__thumbnail"
              fluid={video.thumbnail.asset.fluid}
              alt={video.title}
            />
          </div>
        </div>
        <h4 className="video__title">{video.title}</h4>
      </VideoStyles>
    </>
  )
}

export default Video
