import React from "react"
import styled from "styled-components"

const VideoStyles = styled.div`
  width: 100%;
  .video {
    overflow: hidden;
    padding-top: 56.25%;
    position: relative;
  }
  .video iframe {
    border: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
`

function Video({ videoSrc, videoTitle, ...props }) {
  console.log(props)
  return (
    <VideoStyles>
      <div className="video">
        {/* <iframe
          src={videoSrc}
          title={videoTitle}
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          frameBorder="0"
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
          allowFullScreen
        /> */}
        <iframe
          width="300"
          height="250"
          src={videoSrc}
          title={videoTitle}
          frameborder="0"
          allowFullScreen
        />
      </div>
    </VideoStyles>
  )
}

Video.defaultProps = {
  src:
    "https://odysee.com/$/embed/holdup-hold-up-le-film/b15c347a109423fab76945251233bb6f50a43bd9?r=Yc44WBTJ8jvNvGjTNH9Podv1V2ahutP9",
  title: "Hold Up Documentary",
}

export default Video
